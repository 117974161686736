const subheadingsData = {
  1: [
    {
      title: "GattoWif is more than just a meme coin",
      content: (
        <>
          <p>
          the newest star in the meme coin universe! With its playful name and ambitious spirit, 
          GattoWif is quickly gaining traction among crypto enthusiasts. Fueled by a dedicated
           team and passionate community, GattoWif is more than just a meme coin—it's a beacon of 
           innovation in the decentralized world. Join the journey and embrace the meme! 🐱
          </p>
        </>
      ),
    },
  ],
  2: [
    {
      title: "MEOW COMMUNITY FIRST",
      content: (
        <>
          <p>
          where every purrson matters! We're more than just a meme coin;
           we're a community of cat lovers united in our passion for crypto 
           and kitties. Join us for a paw-some journey filled with memes, joy, 
           and support. Together, we'll make GattoWif the purr-fect place
            for all things feline and finance! 🐾🚀
          </p>
        </>
      ),
    },
    {
      title: "LOVE MEOW (GATTOWIF)",
    },
  ],
  3: [
    {
      title: "100M TOTAL SUPPLY",
      content: (
        <>
          <p>
         GATTO WIF tokenomics contains an allocation for the airdrop, giving the Smog community access to the greatest airdrop in history, distributed on Solana.
          </p>
        </>
      ),
    },
    {
      title: "50% SUPPLY",
      content: (
        <>
          <p>
          50% of the supply will be used for marketing efforts
          </p>
        </>
      ),
    },
    {
      title: "50% SUPPLY",
      content: (
        <>
          <p>
          50% of the supply will be used for marketing efforts
          </p>
        </>
      ),
    },
    {
      title: "35% SUPPLY",
      content: (
        <>
          <p>
          35% of the supply will be allocated to airdrop rewards
          </p>
        </>
      ),
    },
    {
      title: "10% SUPPLY",
      content: (
        <>
          <p>
          10% of the supply is reserved for CEX launches
          </p>
        </>
      ),
    },
    
    {
      title: "5% SUPPLY",
      content: (
        <>
          <p>
         5% of the supply is reserved for adding liquidity for the DEX launch
          </p>
        </>
      ),
    },

    {
      title: "GATTO WIF Token Addresses",
      content: (
        <>
          <p>
         XSAXSADA234234ASXASDSD
          </p>
        </>
      ),
    },


  ],
};

export default subheadingsData;
