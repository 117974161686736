const skills = {
  1: [
    {
      title: "100HOLDERS",
      level: 0,
    },
    {
      title: "900HOLDERS",
      level: 0,
    },
    {
      title: "1500HOLDERS",
      level: 0,
    },
    {
      title: "3500HOLDERS",
      level: 0,
    },
    {
      title: "7000HOLDERS",
      level: 0,
    },
    {
      title: "15000HOLDERS",
      level: 0,
    },
    {
      title: "50kHOLDERS",
      level: 0,
    },
    {
      title: "100kHOLDERS",
      level: 0,
    },
  ],
  
};

export default skills;
